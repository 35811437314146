/* eslint-disable react-func/max-lines-per-function */
import React, { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Checkbox, Text } from '../../../../../youtalk-storybook/src/ui'
import { CheckboxRowGroup, Checkboxes } from '../index.styles'
import { Field, useField, useFormState } from 'react-final-form'
import { ReactComponent as IconClockSVG } from './img/clock.svg'
import { SessionType } from '../../../../atoms/mappers/gqlEnums/sessionType'
import { ReactComponent as UrgentIconSVG } from '../../img/urgent.svg'
import { selectDeviceSizeIsMobile } from '../../../../state/reducers/deviceSlice'
import { selectorValueToProps } from '../WebView/SelectTimeForm'
import { useSelector } from 'react-redux'

export const TextWithIcon = styled(({ className }) => (
  <div className={className}>
    <IconClockSVG />
    <Text.Average semiBold>
      Ваш часовой пояс (GMT {dayjs().format('Z')})
    </Text.Average>
  </div>
))`
  display: flex;
  gap: 4px;
  align-items: center;
  color: #838383; // add to colors
`

const TimezoneInfo = styled(({ className }) => (
  <div className={className}>
    <Text.Average bold>Выберите время</Text.Average>
    <TextWithIcon />
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SlotInfo = styled(({ className, option }) => (
  <div className={className}>
    {option.text}
    {option.isUrgent ? <UrgentIconSVG /> : <></>}
  </div>
))`
  display: flex;
  gap: 4px;
  align-items: center;
`

// eslint-disable-next-line max-lines-per-function
export const TimeSlotsField = ({
  psychologistAvailableSlots,
  loadingSlots,
  updateIsUrgent
}) => {
  const { values } = useFormState()

  const {
    input: { onChange }
  } = useField('time')
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  const options = useMemo(
    () =>
      psychologistAvailableSlots
        .filter(({ datetime }) => dayjs(datetime).isSame(values.date, 'date'))
        .map(({ datetime, isUrgent }) => ({
          text: dayjs(datetime).format('HH:mm'),
          id: datetime,
          value: datetime,
          isUrgent
        })),
    [psychologistAvailableSlots, values.date]
  )

  const [type] = selectorValueToProps(values.sessionTypeAndLanguage)
  const isAsyncChat = type === SessionType.AsyncChat

  useEffect(() => {
    if (loadingSlots || !values.time || isAsyncChat) {
      return
    }
    const [hours, minutes] = values.time.split(':').map(Number)
    const currentDate = values.date.set('hours', hours).set('minutes', minutes)

    const isActiveSlot = psychologistAvailableSlots.some(({ datetime }) =>
      dayjs(datetime).isSame(currentDate, 'minutes')
    )
    updateIsUrgent(
      psychologistAvailableSlots.find(({ datetime }) =>
        dayjs(datetime).isSame(currentDate, 'minutes')
      )?.isUrgent
    )

    if (isActiveSlot) {
      return
    } else {
      onChange(undefined)
    }
  }, [psychologistAvailableSlots, values])

  return (
    <Checkboxes>
      {!isAsyncChat && !isMobile && <TimezoneInfo />}
      {!isAsyncChat && options?.length > 0 && (
        <CheckboxRowGroup>
          {options.map((option) => (
            <Field
              key={option.id}
              name="time"
              type="radio"
              value={dayjs(option.value).format('HH:mm')}
            >
              {({ input }) => (
                <Checkbox
                  {...input}
                  key={option.id}
                  id={option.id}
                  shape="square"
                  size={isMobile ? 'large' : 'medium'}
                  value={dayjs(option.value).format('HH:mm')}
                  width={74}
                >
                  <SlotInfo option={option} />
                </Checkbox>
              )}
            </Field>
          ))}
        </CheckboxRowGroup>
      )}
    </Checkboxes>
  )
}

/* eslint-disable react-func/max-lines-per-function */
import { Event as GAEvent } from '../../../../components/GA'
import { RegistrationSource } from '../../../../atoms/mappers/gqlEnums/registrationSourse'
import { RegistrationStep } from '../vars'
import { SourceID } from '../../../../atoms/mappers/wizard/enums/sourceId'
import { amplitudeIdentifyLeadId } from '../../../../components/amplitude/identify'
import {
  getAnalyticsDataFromCookies,
  getAnalyticsDataFromUtm
} from '../../../../atoms/useFormValues'
import { omit } from 'rambda'
import { sendRegistrationRequestInputSchema } from '../schema'
import { useErrorModalContext } from '../../hooks/useErrorProvider'
import { useFormattedCatalogFiltersData } from '../../hooks/useFormattedCatalogFiltersData'
import { usePsyCardStorage } from '../../../Information/usePsyCardStorage'
import { useRegistrationModalContext } from './useRegistrationModalContext'
import { useSendRegistrationRequestMutation } from '../../query'

export const useIdentityStepSubmit = (psychologist) => {
  const context = useRegistrationModalContext()

  const [storedValue] = usePsyCardStorage()
  const formattedCatalogFiltersData = useFormattedCatalogFiltersData(
    storedValue,
    psychologist
  )
  const errorContext = useErrorModalContext()
  const [firstStepRegisterMutation] = useSendRegistrationRequestMutation()

  // eslint-disable-next-line consistent-return
  return async (values) => {
    try {
      const input = {
        phone: values.tel,
        name: formattedCatalogFiltersData.name,
        age: Number(formattedCatalogFiltersData.age),
        userRegistrationPreferences: omit(
          ['age', 'name', 'sourceId'],
          formattedCatalogFiltersData
        ),
        registrationSource: RegistrationSource.Wizard,
        promocodeName: values.promo,
        attempt: values.attempt,
        captchaToken: values.captchaToken,
        analyticsData: {
          sourceId: formattedCatalogFiltersData.sourceId ?? SourceID.UC_LWQXL8,
          pointOfContact: 'wizard_subscription',
          ...getAnalyticsDataFromUtm(),
          ...getAnalyticsDataFromCookies()
        }
      }
      try {
        await sendRegistrationRequestInputSchema.validate(input)
        GAEvent.submitLeadCreate()
      } catch (error) {
        return { tel: error.message }
      }

      const response = await firstStepRegisterMutation({
        variables: {
          input
        }
      })

      if (response.data.sendRegistrationRequest?.token) {
        amplitudeIdentifyLeadId(
          response.data.sendRegistrationRequest?.bitrixLeadId
        )
        context.updateContext({
          step: RegistrationStep.CODE,
          token: response.data.sendRegistrationRequest?.token,
          retryToken: response.data.sendRegistrationRequest?.token,
          datetime: response.data.sendRegistrationRequest?.datetime,
          retryAvailableAfter:
            response.data.sendRegistrationRequest?.retryAvailableAfter,
          canBeRequestAgain: false,
          tel: values.tel,
          attempt: 1
        })
        return response.data.sendRegistrationRequest?.token
      } else {
        errorContext.onServerError()
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      errorContext.onServerError()
    }
  }
}

import React, { forwardRef, useEffect, useRef, useState } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import { AvatarBlock } from './avatarBlock'
import { BadgesFull } from '../../molecules/Badges/BadgesFull'
import {
  CheckboxTabsToggler,
  useCheckboxTabsToggler
} from '../../molecules/CheckboxToggler'
import { InformationAbout } from './aboutPsychologist'
import { InformationApproaches } from './approachesInfo'
import { InformationEducations } from './educationInfo'
import { PsychologistFAQBlock } from './PsychologistFAQBlock'
import { PsychologistReviewsBlock } from '../../molecules/Reviews'
import { WizardCheckbox } from '../../atoms/Checkbox'
import { WorkAreasBlock } from './workAreasBlock'
import { color } from '../../../youtalk-storybook/src/ui'
import { navigate } from 'gatsby'
import { size } from '../../constants'
import { trackPressReview } from '../../components/amplitude/trackPressReview'
import { useLocation } from '@reach/router'

const tabsData = [
  {
    id: 'about',
    text: 'О себе',
    value: 'about'
  },
  {
    id: 'approaches',
    text: 'Подход',
    value: 'approaches'
  },
  {
    id: 'education',
    text: 'Образование',
    value: 'education'
  }
]

const InformationCheckboxes = styled(({ className, checked, handleCheck }) => (
  <CheckboxTabsToggler
    checkboxes={tabsData}
    checked={checked}
    className={className}
    handleCheck={handleCheck}
    initialValue={checked}
    size="medium"
  />
))`
  width: fit-content;
  height: 40px;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }

  ${WizardCheckbox} {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    @media (max-width: ${size.xs}) {
      & label {
        padding-left: 11px;
        padding-right: 11px;
      }
    }

    @media (max-width: ${size.xs375}) {
      & label {
        font-size: 12px;
        line-height: 16px;
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }
`

const InformationBlock = styled(({ psychologist, className }) => {
  const [checked, handleCheck] = useCheckboxTabsToggler('about')

  return (
    <div className={className}>
      <InformationCheckboxes checked={checked} handleCheck={handleCheck} />
      {checked === 'about' && <InformationAbout psychologist={psychologist} />}
      {checked === 'approaches' && (
        <InformationApproaches psychologist={psychologist} />
      )}
      {checked === 'education' && (
        <InformationEducations psychologist={psychologist} />
      )}
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const PsychologistPageBlock = styled(
  forwardRef(({ className, children }, ref) => (
    <div ref={ref} className={className}>
      {children}
    </div>
  ))
)`
  border-radius: 16px;
  background: ${color.background};
  display: flex;
  flex-direction: column;
  padding: 24px;
`

export const PsychologistInfo = styled(({ psychologist, className }) => {
  const [isNeedToScroll, setIsNeedToScroll] = useState(false)
  const showBlock = psychologist.reviews && psychologist.reviews.length > 0

  const reviewsBlockRef = useRef(null)

  const onReviewsClick = () => {
    trackPressReview()
    if (reviewsBlockRef.current) {
      reviewsBlockRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const { search, pathname } = useLocation()
  const searchObj = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  const { pt_reviews_clicked: isReviewsClicked } = searchObj

  useEffect(() => {
    if (isReviewsClicked) {
      trackPressReview()
      const { pt_reviews_clicked: _, ...newSearchObj } = searchObj
      navigate(`${pathname}?${qs.stringify(newSearchObj)}`)
      setIsNeedToScroll(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let timeout
    if (isNeedToScroll) {
      timeout = setTimeout(() => {
        reviewsBlockRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToScroll])

  return (
    <div className={className}>
      <PsychologistPageBlock>
        <AvatarBlock
          onReviewsClick={onReviewsClick}
          psychologist={psychologist}
        />
        <BadgesFull psychologist={psychologist} />
        <WorkAreasBlock psychologist={psychologist} />
        <InformationBlock psychologist={psychologist} />
      </PsychologistPageBlock>
      {showBlock && (
        <PsychologistPageBlock ref={reviewsBlockRef}>
          <PsychologistReviewsBlock
            reviews={psychologist.reviews}
            scrollRef={reviewsBlockRef}
          />
        </PsychologistPageBlock>
      )}
      <PsychologistPageBlock>
        <PsychologistFAQBlock />
      </PsychologistPageBlock>
    </div>
  )
})`
  width: 588px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;

  @media (max-width: ${size.md}) {
    width: 374px;
  }

  @media (max-width: ${size.sm}) {
    display: contents;
    width: 100%;
    margin: 0 20px;
  }
`

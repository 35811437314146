import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { FormItemOnControl } from '../../molecules/FormItem'
import { SmartCaptcha } from '@yandex/smart-captcha'
import { notEmpty } from '../../atoms/FormItems/validators/notEmpty'
import { useFetchConfigEffect } from './useFetchConfigEffect'
import { useSelector } from 'react-redux'

export const Captcha = ({ name = 'captchaToken' }) => {
  useFetchConfigEffect()

  const config = useSelector((state) => state.config.yandexCaptcha)
  const [key, setKey] = useState(0)

  const onExpire = () => {
    setKey((prevKey) => prevKey + 1)
  }

  if (!config.enabled) return null

  return (
    <Field
      name={name}
      validate={notEmpty('Пожалуйста, поставьте галочку, что вы не робот')}
    >
      {({ input, meta }) => (
        <FormItemOnControl required meta={meta}>
          <SmartCaptcha
            key={key}
            // eslint-disable-next-line react/jsx-handler-names
            onSuccess={input.onChange}
            onTokenExpired={onExpire}
            sitekey={config.clientKey}
            test={config.test}
          />
        </FormItemOnControl>
      )}
    </Field>
  )
}

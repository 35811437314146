import { gql, useQuery } from '@apollo/client'

const GET_PSYCHOLOGIST_IS_ACTIVE = gql`
  query psychologistsCatalogItem($id: String!) {
    psychologistsCatalogItem(id: $id) {
      isActive
      visibility
      userID
      isWorkingWithTeenagerPatients
    }
  }
`

const staticRequestProps = {
  fetchPolicy: 'network-only',
  pollInterval: 1000 * 60 * 10 //10 min autorefetch,
}

export const useGetPsychologistActualData = (input) =>
  useQuery(GET_PSYCHOLOGIST_IS_ACTIVE, {
    ...staticRequestProps,
    ...input
  })

import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BigGiftSVG } from '../img/bigGift.svg'
import { ReactComponent as IconGiftSVG } from '../img/gift.svg'
import { ReactComponent as SecondGiftSVG } from '../img/secondGift.svg'
import { TIMERS, useShowWithTimer } from '../hooks/useFakeTimer'
import { Text } from '../../../../../youtalk-storybook/src/ui'
import { size } from '../../../../constants'

const GuideTitle = styled(Text.Medium)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  margin-bottom: 8px;

  @media (max-width: ${size.sm}) {
    max-width: 75%;
  }
`

const GuideTimer = styled(Text.Medium)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #667085;
`

const GuideTimerBlock = styled.div`
  display: flex;
  gap: 8px;
  width: 164px;
`
const SecondGift = styled(SecondGiftSVG)`
  @media (max-width: ${size.md}) {
    display: none;
  }
`

const RightBlock = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  right: 200px;
  bottom: 0;
  align-items: end;

  @media (max-width: ${size.md}) {
    right: 16px;
  }
  @media (max-width: ${size.xs}) {
    right: 0;
  }
`

export const GuideBlock = styled(({ isMovileView, className }) => {
  const timeRemaining = useShowWithTimer(TIMERS.GUIDE_BANNER)

  if (!timeRemaining) return null

  return (
    <div className={className}>
      <GuideTitle bold>
        Получите гайд по борьбе со стрессом за первую сессию
      </GuideTitle>
      <RightBlock isMovileView={isMovileView}>
        <BigGiftSVG />
        <SecondGift />
      </RightBlock>

      <GuideTimerBlock>
        <IconGiftSVG />
        <GuideTimer>Осталось: {timeRemaining}</GuideTimer>
      </GuideTimerBlock>
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 16px;
  background-color: #f0f9ff;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  width: 999px;
  align-self: center;
  height: 100px;
  margin-bottom: 24px;
  height: 52px;
  overflow: hidden;

  @media (max-width: ${size.md}) {
    width: 704px;
    height: 80px;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    height: 100%;
    width: 100%;
    margin-bottom: 16px;
  }
`

/* eslint-disable no-unused-vars */
import { amplitudeIdentifyAbTestParticipants } from '../components/amplitude/identify'
import { isBrowser } from '../atoms/root'
import { useLocalStorage } from '../../youtalk-storybook/src/ui'

export enum AbTestsNames {
  EXAMPLE = 'EXAMPLE',
  NEGATIVE_RATING = 'negative_rating',
  OTHER_PSYCHOLOGISTS_CARDS = 'other_psychologists_cards',
  IS_MONEY_BACK_GUARATEE = 'IsMoneyBackGuaranteeTest'
}

export enum InvariantValues {
  YES = 'yes',
  NO = 'no',
  NONE = 'none'
}

type InvariantWithWeight = {
  value: InvariantValues
  weight: number
}

type LocalStorageType = InvariantValues | undefined

type TestData = {
  [key: string]: InvariantWithWeight[]
}

const testData: TestData = {
  [AbTestsNames.EXAMPLE]: [
    {
      value: InvariantValues.YES,
      weight: 0.15
    },
    {
      value: InvariantValues.NO,
      weight: 0.15
    },
    {
      value: InvariantValues.NONE,
      weight: 0.7
    }
  ],
  [AbTestsNames.NEGATIVE_RATING]: [
    {
      value: InvariantValues.YES,
      weight: 0.5
    },
    {
      value: InvariantValues.NO,
      weight: 0.5
    }
  ],
  [AbTestsNames.OTHER_PSYCHOLOGISTS_CARDS]: [
    {
      value: InvariantValues.YES,
      weight: 0.5
    },
    {
      value: InvariantValues.NO,
      weight: 0.5
    }
  ],
  [AbTestsNames.IS_MONEY_BACK_GUARATEE]: [
    {
      value: InvariantValues.YES,
      weight: 0.075
    },
    {
      value: InvariantValues.NO,
      weight: 0.075
    },
    {
      value: InvariantValues.NONE,
      weight: 0.85
    }
  ]
}

type TestEvents = {
  [key: string]: (value: any) => void
}

const testEvents: TestEvents = {
  // eslint-disable-next-line no-console
  [AbTestsNames.EXAMPLE]: (value) => console.log('track event', { value })
}

const getRandomInitValue = (invariants: InvariantWithWeight[]) => {
  const weights = invariants.reduce<number[]>((acc, item, i) => {
    acc.push(item.weight + (acc[i - 1] || 0))
    return acc
  }, [])
  const random = Math.random() * weights[weights.length - 1]
  const randonIndex = weights.findIndex((weight) => random <= weight)
  return invariants[randonIndex].value
}

export const useAbTest = (
  testName: AbTestsNames,
  // инициировать AB тест для пользователя
  // иначе вернуть текущее значение, при отсутствии последнего - не участвует
  initializeAbTest: boolean = false,
  // пользователь принудительно не попадает в AB тест
  isNotParticipantForcePick: boolean = false,
  //нужно ли трекать отдельное событие в амплитуду
  isNeedToTrackAmplitudeEvent: boolean = false
) => {
  const [value, setValue] = useLocalStorage<LocalStorageType>(
    testName,
    undefined
  )
  if (value === undefined) {
    const setLocalStorageAndAmplitudeProperty = (
      invariantValue: InvariantValues
    ) => {
      invariantValue !== InvariantValues.NONE &&
        amplitudeIdentifyAbTestParticipants(testName, invariantValue)
      setValue(invariantValue)
    }

    if (!initializeAbTest || isNotParticipantForcePick) {
      setLocalStorageAndAmplitudeProperty(InvariantValues.NONE)
      if (isNeedToTrackAmplitudeEvent) {
        const trackFunction = testEvents[testName]
        trackFunction(InvariantValues.NONE)
      }
      return InvariantValues.NONE
    }

    // for QA only
    if (isBrowser) {
      const qaForceValue = window.localStorage.getItem(`${testName}_qa`)
      const forceInvariant = testData[testName].find(
        (item) => item.value === qaForceValue
      )
      if (forceInvariant) {
        setLocalStorageAndAmplitudeProperty(forceInvariant.value)
        return forceInvariant.value
      }
    }

    const initItemValue = getRandomInitValue(testData[testName])
    setLocalStorageAndAmplitudeProperty(initItemValue)

    if (isNeedToTrackAmplitudeEvent) {
      const trackFunction = testEvents[testName]
      trackFunction(initItemValue)
    }
    return initItemValue
  }
  return value
}

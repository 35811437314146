import { getConfig } from '../../api'
import { saveConfig } from '../../state/reducers/configSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

export const useFetchConfigEffect = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    getConfig().then((config) => {
      dispatch(saveConfig(config))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

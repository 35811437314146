import React from 'react'
import styled from 'styled-components'
import { InfoAddBlock } from '../InfoAddBlock'
import { SelectTimeButton } from '../WebView/index.styles'
import { SessionTypeSelector } from '../WebView/SelectTimeForm'

const SelectTariff = styled.div`
  height: 60px;
`

const WrapperWithGuide = styled.div`
  padding: 14px 20px;
`

export const MobileView = styled(
  ({ onClickSelectTimeButton, psychologist, popupContainerId, className }) => (
    <div className={className}>
      <WrapperWithGuide>
        <SelectTariff>
          <SessionTypeSelector
            popupContainerId={popupContainerId}
            psychologist={psychologist}
          />
        </SelectTariff>
        <SelectTimeButton onClick={onClickSelectTimeButton} size="medium">
          Выбрать время
        </SelectTimeButton>
      </WrapperWithGuide>
      <InfoAddBlock />
    </div>
  )
)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: sticky;
  bottom: 0px;
  z-index: 5;
  box-shadow: 3px 1px 0px 0px #191c1f0a, 3px -1px 2px 0px #191c1f0f,
    3px -3px 6px 0px #191c1f0f;
  width: 100%;
`

import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Illustrations,
  Popup,
  Text
} from '../../../../../youtalk-storybook/src/ui'
import { MessengersPopup } from '../../../../molecules/MessengersPopup'
import { ReactComponent as RefundSVG } from './refund.svg'
import { color } from '../../../../styles/vars/colors'
import { selectDeviceSizeIsMobile } from '../../../../state/reducers/deviceSlice'
import { size } from '../../../../constants'
import { useSelector } from 'react-redux'

const TextWithLink = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  margin-bottom: 8px;
  @media (max-width: ${size.sm}) {
    max-width: 75%;
  }

  ${Text.Average} {
    display: inline-block;
    ${Text.Average} {
      color: ${color.link.default};
      &:visited,
      &:focus,
      &:hover {
        color: ${color.link.default};
      }

      &:disabled {
        color: ${color.link.disabled};
      }
      color: #008ae9;
      cursor: pointer;
    }
  }
`

const RefundPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const RefundTitle = styled(Text.Medium)`
  font-weight: 700;
  margin-bottom: 8px;

  @media (max-width: ${size.sm}) {
    max-width: 75%;
  }
`
const PopupRefundTitle = styled(Text.Large)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 24px;

  @media (max-width: ${size.sm}) {
    max-width: 75%;
  }
`
const ImageShield = styled(RefundSVG)`
  width: 40px;
  padding-top: 4px;
  height: 40px;
`
const PopupStyled = styled(Popup)`
    @media (max-width: ${size.sm}) {
      height: var(--height);
      }
    }
`

export const RefundBlock = styled(({ className }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  const [isRefundPopup, setRefundPopup] = useState(false)
  const [isMessengersPopup, setMessengersPopup] = useState(false)
  return (
    <div className={className}>
      <ImageShield />
      <div>
        <RefundTitle>Гарантия возврата денег</RefundTitle>
        <TextWithLink>
          <Text.Average>
            Напишите нам, если&nbsp;сессия пройдёт не&nbsp;так,
            как&nbsp;вы&nbsp;ожидали,{' '}
            <Text.Average onClick={() => setRefundPopup(true)}>
              вернём&nbsp;деньги
            </Text.Average>
          </Text.Average>
        </TextWithLink>
      </div>
      {isRefundPopup && (
        <PopupStyled
          hide={() => setRefundPopup(false)}
          isMobile={isMobile}
          showCloseButton={!isMobile}
        >
          <RefundPopupContent>
            <Illustrations.IllustrationPaid
              size={isMobile ? 'small' : 'medium'}
            />
            <PopupRefundTitle>Гарантия возврата денег</PopupRefundTitle>

            <TextWithLink>
              <Text.Average>
                Если&nbsp;сессия не&nbsp;состоялась, психолог повёл себя
                непрофессионально или&nbsp;что‑то пошло не&nbsp;так, просто{' '}
                <Text.Average
                  onClick={() => {
                    setRefundPopup(false)
                    setMessengersPopup(true)
                  }}
                >
                  напишите&nbsp;нам
                </Text.Average>{' '}
                в&nbsp;поддержку: разберёмся, сменим психолога или&nbsp;проведём
                возврат
              </Text.Average>
            </TextWithLink>
          </RefundPopupContent>
        </PopupStyled>
      )}
      {isMessengersPopup && (
        <MessengersPopup hide={() => setMessengersPopup(false)} />
      )}
    </div>
  )
})`
  display: flex;
  position: relative;
  padding: 16px;
  background-color: #f0f9ff;
  border-bottom-left-radius: ${(props) =>
    props.isMovileView ? '0px' : '16px'};
  border-bottom-right-radius: ${(props) =>
    props.isMovileView ? '0px' : '16px'};
  border: 1px solid #e6e6e6;
`

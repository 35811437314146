import React, { useMemo, useState } from 'react'
import SmileClosed1 from './img/icons/Closed_1.svg'
import SmileClosed2 from './img/icons/Closed_2.svg'
import SmileClosed3 from './img/icons/Closed_3.svg'
import SmileClosed4 from './img/icons/Closed_4.svg'

import SmileOpen1 from './img/icons/Open_1.svg'
import SmileOpen2 from './img/icons/Open_2.svg'
import SmileOpen3 from './img/icons/Open_3.svg'
import SmileOpen4 from './img/icons/Open_4.svg'

import SmileWink1 from './img/icons/Wink_1.svg'
import SmileWink2 from './img/icons/Wink_2.svg'
import SmileWink3 from './img/icons/Wink_3.svg'
import SmileWink4 from './img/icons/Wink_4.svg'

import styled from 'styled-components'
import { Link } from 'gatsby'
import { ReactComponent as ReviewsSVG } from './img/roundReviewsIcon.svg'
import { ShowOrHideButton } from '../../organisms/Information/workAreasBlock'
import { Text } from '../../atoms/Text'
import { formatReviewsTextByCount } from '../../atoms/pluralFormatters'
import { isBrowser } from 'framer-motion'

const images = [
  SmileClosed1,
  SmileClosed2,
  SmileClosed3,
  SmileClosed4,
  SmileOpen1,
  SmileOpen2,
  SmileOpen3,
  SmileOpen4,
  SmileWink1,
  SmileWink2,
  SmileWink3,
  SmileWink4
]

const getRandomImage = () => images[Math.floor(Math.random() * images.length)]

const ReviewsBlock = styled(Link)`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 4px;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`

const ReviewsButton = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 4px;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`

const ReviewsTitle = styled(Text.Average)`
  color: #008ae9;
`

export const Reviews = ({
  reviewsCount,
  onReviewsClick,
  moreDetailsButtonProps
}) => (
  <>
    {moreDetailsButtonProps ? (
      <ReviewsBlock
        href={`${moreDetailsButtonProps.href}&pt_reviews_clicked=true`}
        target="_blank"
        type="link"
      >
        <ReviewsSVG />
        <ReviewsTitle semiBold>
          {reviewsCount} {formatReviewsTextByCount(reviewsCount)}
        </ReviewsTitle>
      </ReviewsBlock>
    ) : (
      <ReviewsButton onClick={() => onReviewsClick()}>
        <ReviewsSVG />
        <ReviewsTitle semiBold>
          {reviewsCount} {formatReviewsTextByCount(reviewsCount)}
        </ReviewsTitle>
      </ReviewsButton>
    )}
  </>
)

const StyledTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 4px;
`

const StyledSubtitle = styled(Text.Medium)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  color: #838383;
`
const DEFAULT_NUMBER_OF_REVIEWS_SHOW = 1

const ReviewTag = styled.div`
  display: flex;
  padding: 2px 16px;
  background-color: #f8f9f9; // add to colors
  border-radius: 16px;
  & ${Text.Average} {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
`

const ReviewTitle = styled(Text.Medium)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const ReviewTitleBlock = styled.div`
  display: flex;
  gap: 8px;
`

const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ReviewTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const ReviewText = styled(Text.Medium)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #dadada;
  margin-bottom: 20px;
`

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
`

const Review = styled(({ review, clientImage, className }) => (
  <ReviewContent className={className}>
    <ReviewTitleBlock>
      <StyledImage alt="Emoji" src={clientImage} />
      <ReviewTitle semibold>
        {review.clientName}, {review.clientAge} лет
      </ReviewTitle>
    </ReviewTitleBlock>
    <ReviewTags>
      {review.tags.map((tag) => (
        <ReviewTag key={tag}>
          <Text.Average bold>{tag}</Text.Average>
        </ReviewTag>
      ))}
    </ReviewTags>
    <ReviewText>{review.text}</ReviewText>
  </ReviewContent>
))`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`

const ReviewsList = styled(({ reviews, showAll, className }) => {
  if (!isBrowser) return null

  const reviewsToShow = useMemo(() => {
    if (showAll) {
      return reviews
    }
    return reviews.slice(0, DEFAULT_NUMBER_OF_REVIEWS_SHOW)
  }, [reviews, showAll])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const randomImages = useMemo(() => reviews.map(() => getRandomImage()), [])

  return (
    <div className={className}>
      {reviewsToShow.map((review, index) => (
        <>
          <Review
            key={review.id}
            clientImage={randomImages[index]}
            review={review}
          />
          {showAll && index < reviewsToShow.length - 1 && <Divider />}
        </>
      ))}
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PsychologistReviewsBlock = styled(
  ({ reviews, scrollRef, className }) => {
    const [showAll, setShowAll] = useState(false)
    const showButtons = reviews.length > DEFAULT_NUMBER_OF_REVIEWS_SHOW

    const scrollTo = () => {
      scrollRef.current?.scrollIntoView()
    }
    return (
      <div className={className}>
        <StyledTitle>Отзывы</StyledTitle>
        <StyledSubtitle>
          Выбрали самые информативные и наиболее актуальные. Изменили имя
          клиента, чтобы сохранить конфиденциальность.
        </StyledSubtitle>
        <ReviewsList reviews={reviews} showAll={showAll} />
        {showButtons && (
          <ShowOrHideButton
            scrollTo={scrollTo}
            setShowAll={setShowAll}
            showAll={showAll}
          />
        )}
      </div>
    )
  }
)`
  display: flex;
  flex-direction: column;
`

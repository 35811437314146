import React from 'react'
import {
  AbTestsNames,
  InvariantValues,
  useAbTest
} from '../../../hooks/useAbTest'
import { RefundBlock } from './RefundBlock'

export const InfoAddBlock = () => {
  const isMoneyBackGuaranteeTest = useAbTest(
    AbTestsNames.IS_MONEY_BACK_GUARATEE
  )
  const showRefundBlock = isMoneyBackGuaranteeTest === InvariantValues.YES
  return showRefundBlock ? <RefundBlock /> : null
}

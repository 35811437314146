import { RegistrationSource } from '../../../../atoms/mappers/gqlEnums/registrationSourse'
import { RegistrationStep } from '../../vars'
import { SourceID } from '../../../../atoms/mappers/wizard/enums/sourceId'
import { amplitudeIdentifyLeadId } from '../../../../components/amplitude/identify'
import {
  getAnalyticsDataFromCookies,
  getAnalyticsDataFromUtm
} from '../../../../atoms/useFormValues'
import { omit } from 'rambda'
import { useErrorModalContext } from '../../hooks/useErrorProvider'
import { useFormattedCatalogFiltersData } from '../../hooks/useFormattedCatalogFiltersData'
import { useSendCodeModalContext } from './useSendCodeModalContext'
import { useSendRegistrationRequestMutation } from '../../query'

export const useIdentitySubmit = () => {
  const context = useSendCodeModalContext()

  const formattedCatalogFiltersData = useFormattedCatalogFiltersData(
    context.catalogFiltersData
  )
  const errorContext = useErrorModalContext()

  const [firstStepRegisterMutation] = useSendRegistrationRequestMutation()

  return async (values) => {
    try {
      const input = {
        phone: values.tel,
        name: formattedCatalogFiltersData.name,
        age: Number(formattedCatalogFiltersData.age),
        userRegistrationPreferences: omit(
          ['age', 'name', 'sourceId'],
          formattedCatalogFiltersData
        ),
        attempt: values.attempt,
        registrationSource: RegistrationSource.Form,
        promocodeId: context.formData.promocodeId,
        timezone: context.formData.timezone,
        email: context.formData.email,
        analyticsData: {
          sourceId: formattedCatalogFiltersData.sourceId ?? SourceID.UC_LWQXL8,
          ...getAnalyticsDataFromUtm(),
          ...getAnalyticsDataFromCookies()
        }
      }
      const response = await firstStepRegisterMutation({
        variables: { input }
      })
      if (response.data.sendRegistrationRequest?.token) {
        amplitudeIdentifyLeadId(
          response.data.sendRegistrationRequest?.bitrixLeadId
        )
        context.updateContext({
          step: RegistrationStep.CODE,
          token: response.data.sendRegistrationRequest?.token,
          datetime: response.data.sendRegistrationRequest?.datetime,
          retryAvailableAfter:
            response.data.sendRegistrationRequest?.retryAvailableAfter,
          canBeRequestAgain: false,
          attempt: 1
        })
        return response.data.sendRegistrationRequest?.token
      } else {
        errorContext.onServerError()
      }
    } catch (err) {
      console.log(err)
      errorContext.onServerError()
    }
  }
}

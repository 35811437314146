import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BadgeMiniNewbieSVG } from './img/badgeMiniNewbie.svg'
import { ReactComponent as BadgeMiniStarSVG } from './img/badgeMiniStar.svg'
import { Badges } from './const'
import { Text } from '../../../youtalk-storybook/src/ui'

const ImageMap = {
  [Badges.HIGH_RATING]: <BadgeMiniStarSVG />,
  [Badges.START_WORKING]: <BadgeMiniNewbieSVG />
}

const ColorMap = {
  [Badges.HIGH_RATING]:
    'radial-gradient(88.19% 157.3% at 13.94% 13.32%, #F9E775 0%, #FAC507 100%)',
  [Badges.START_WORKING]:
    'radial-gradient(88.19% 157.3% at 13.94% 13.32%, #D4EDDA 0%, #B2E3B2 100%)'
}

const PsychologistMiniBadge = styled(({ className, badge }) => (
  <div className={className}>
    {ImageMap[badge.code]}
    <Text.Average bold>{badge.name}</Text.Average>
  </div>
))`
  display: flex;
  padding: 0px 8px 0px 4px;
  align-items: center;
  gap: 2px;
  border-radius: 16px;
  background: ${({ badge }) =>
    ColorMap[badge.code] ?? ColorMap[Badges.HIGH_RATING]};

  ${Text.Average} {
    font-weight: 600;
  }
`

export const BadgesMini = styled(({ className, badges }) => {
  if (!badges) {
    return null
  }

  return (
    <div className={className}>
      {badges.map((badge) => (
        <PsychologistMiniBadge key={badge.id} badge={badge} />
      ))}
    </div>
  )
})`
  position: absolute;
  left: -5px;
  top: -10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

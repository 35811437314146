/* eslint-disable max-lines-per-function */
import Footer from '../components/Footer'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { AbTestsNames, InvariantValues, useAbTest } from '../hooks/useAbTest'
import { AddPsychologistProfileRatingMainFormModal } from './AddPsychologistProfileRatingModal'
import { ApplicationFormBlock } from './Information/applicationFormBlock'
import {
  Button,
  Icons,
  size,
  useLocalStorage,
  usePopup,
  useSessionStorage
} from '../../youtalk-storybook/src/ui'
import { CalendarBlock } from './Information/CalendarView'
import { Grid } from '../atoms/Grid'
import { GuideBlock } from './Information/CalendarView/GuideBlock'
import { Loader } from '../atoms/WizardLoader'
import { NewMiniCard } from './PsychologistNewMiniCard'
import { PSYCHOLOGIST_WORKS_WITH_TEENS } from '../constants'
import { PsychologistInfo } from './Information'
import { SessionFormat } from '../atoms/mappers/gqlEnums/sessionFormat'
import { Text } from '../atoms/Text'
import { VisibilityType } from '../atoms/mappers/gqlEnums/visibilityTypes'
import { color } from '../styles/vars/colors'
import { genderMap } from '../atoms/mappers/wizard/genderMap'
import { getAvatarUrl } from '../atoms/getAvatarUrl'
import { getExperienceWithText } from '../atoms/ageParser'
import { isBrowser } from '../atoms/root'
import { languageMap } from '../atoms/mappers/wizard/languageMap'
import { pick } from 'ramda'
import { pricesMap } from '../atoms/mappers/wizard/pricesMap'
import { selectDeviceSizeIsMobile } from '../state/reducers/deviceSlice'
import { therapyTypeCatalogFilterMap } from '../atoms/mappers/wizard/therapyTypeMap'
import { useAddPsychologistProfileRatingMutation } from './AddPsychologistProfileRatingModal/hooks/query'
import { useCheckIsWizard } from '../hooks/useCheckIsWizard'
import { useGetFilteredPsychologists } from './CatalogContextProvider/hooks/query'
import { useGetPsychologistActualData } from './Information/query'
import { useGetPsychologistAvailableSlotsQuery } from './Information/CalendarView/query'
import { useScrollEffect } from '../atoms/useScrollEffect'
import { useSelector } from 'react-redux'
import { useTrackProfileOpen } from '../components/amplitude/trackProfileOpen'

const getApproachText = (approaches) => {
  const [approach] = approaches ?? []

  return approach?.name
    ? `, работает в таких подходах, как ${approach.name.toLowerCase()}`
    : ''
}

const getApproachTextTitle = (approaches) => {
  const [approach] = approaches ?? []

  return approach?.name ? ` ${approach.name.toLowerCase()}` : ''
}

const getExperienceText = (startOfPractice) =>
  startOfPractice ? ` опыт более ${getExperienceWithText(startOfPractice)}` : ''

const getNameText = (name) => (name ? ` ${name}` : '')

const getSEODescription = ({
  name,
  start_of_practice: startOfPractice,
  approaches,
  surname
}) =>
  `${[
    'Психолог',
    getNameText(name, surname),
    getNameText(surname),
    getExperienceText(startOfPractice),
    getApproachText(approaches)
  ].join('')}. Ознакомиться с информацией о 
  специалисте и записаться на прием вы можете на сайте сервиса YouTalk.`

const getSEOTitle = ({
  name,
  start_of_practice: startOfPractice,
  approaches,
  surname
}) =>
  `${[
    'Психолог ',
    getNameText(name),
    getNameText(surname),
    getExperienceText(startOfPractice),
    getApproachTextTitle(approaches)
  ].join('')} - записаться на онлайн-консультацию | YouTalk`

const getSEOImage = (photoUrl) =>
  pick(photoUrl ? ['images'] : [], {
    images: [
      {
        pathname: getAvatarUrl(photoUrl, 'small_'),
        alt: 'psychologist'
      }
    ]
  })

export const CalendarOrApplicationForm = ({
  isWizard,
  visibility,
  psychologist,
  psychologistUserId
}) => {
  const { data, loading } = useGetPsychologistAvailableSlotsQuery({
    variables: {
      filter: {
        id: psychologistUserId || psychologist.user?.id
      }
    }
  })

  useTrackProfileOpen({
    source: isWizard ? 'wizard' : 'catalog',
    psy_video: psychologist?.video_url ? 'yes' : 'no',
    id_psy: psychologist?.id
  })

  const hasAvailableSlots = useMemo(
    () => data?.psychologistAvailableSlots?.items.length > 0 ?? false,
    [data?.psychologistAvailableSlots?.items]
  )

  const isNeedToHideBlock = visibility
    ? visibility === VisibilityType.Close ||
      visibility === VisibilityType.CloseNewAndDiagnosticians
    : true
  const isVisible = visibility ? visibility === VisibilityType.Open : true
  return (
    <>
      {loading || isNeedToHideBlock ? (
        <></>
      ) : isVisible && hasAvailableSlots ? (
        <CalendarBlock
          isWizard={isWizard}
          psychologist={psychologist}
          psychologistUserId={psychologistUserId}
        />
      ) : (
        <ApplicationFormBlock />
      )}
    </>
  )
}

const LoaderBlock = () => (
  <div>
    <Loader />
  </div>
)

const PsychologistsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 316px);
  gap: 24px;
  width: 100%;

  @media (max-width: ${size.md}) {
    grid-template-columns: none;
    justify-content: start;
    gap: 12px;
  }

  @media (max-width: ${size.sm}) {
    grid-template-columns: none;
    gap: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: ${size.xs}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

const StyledTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 24px;

  @media (max-width: ${size.md}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const AdditionalPsychologists = ({ psychologists }) => (
  <PsychologistsContainer>
    {psychologists.map((psychologist) => (
      <NewMiniCard
        key={psychologist.id}
        isProfileBlock
        psychologist={psychologist}
      />
    ))}
  </PsychologistsContainer>
)

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 48px;
  width: 100%;

  @media (max-width: ${size.md}) {
    padding-right: 32px;
  }

  @media (max-width: ${size.sm}) {
    padding-right: 0px;
    align-items: center;
  }
`

const StyledGrid = styled(Grid)`
  max-width: 996px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  margin-left: 0px;
  align-self: center;

  padding-right: 0px;
  padding-left: 0px;

  @media (max-width: ${size.lg}) {
    margin-right: 32px;
    margin-left: 32px;
  }

  @media (max-width: ${size.md}) {
    max-width: 678px;
    margin-right: 32px;
    margin-left: 32px;
  }
  @media (max-width: ${size.sm}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: ${size.xs}) {
    margin-right: 20px;
  }
`

const UpButton = styled(Button.NoBordersBlack)`
  width: 100%;
  margin-top: 24px;
  background-color: #eff5fb;
  justify-content: center;
  align-items: center;

  @media (max-width: ${size.sm}) {
    max-width: 90%;
  }
`

const UpIcon = styled(Icons.IconArrowUp)`
  margin-left: 12px;
`

const shuffle = (array) => array.sort(() => Math.random() - 0.5)

const getThreeRandomPsychologists = (psychologists) => {
  const shuffledPsychologists = shuffle(psychologists)
  return shuffledPsychologists.slice(0, 3)
}

export const AdditionalPsychologistsBlock = styled(
  ({ scrollRef, psychologists }) => {
    const isMobile = useSelector(selectDeviceSizeIsMobile)

    const additionalPsychologists = useMemo(
      () =>
        psychologists.length >= 10
          ? getThreeRandomPsychologists(psychologists.slice(0, 10))
          : getThreeRandomPsychologists(
              psychologists.slice(0, psychologists.length)
            ),
      [psychologists]
    )

    const scrollTo = () => {
      scrollRef.current?.scrollIntoView()
    }

    return (
      <Container>
        <StyledGrid>
          <StyledTitle>Вам могут подойти</StyledTitle>
          <AdditionalPsychologists psychologists={additionalPsychologists} />
        </StyledGrid>
        {isMobile && (
          <UpButton onClick={() => scrollTo()}>
            Наверх
            <UpIcon />
          </UpButton>
        )}
      </Container>
    )
  }
)`
  width: 100%;
  background-color: #dfebf9;
  padding-bottom: 48px;

  padding-top: 120px;
  background-color: #dfebf9;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 32px;
  }
`

const MainBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (max-width: ${size.xs}) {
    display: block;
  }
`

// eslint-disable-next-line sonarjs/cognitive-complexity
const PsychologistInfoWithCalendar = styled(({ psychologist, className }) => {
  const {
    show: showReviewModal,
    hide: hideReviewModal,
    visible: visibleReviewModal
  } = usePopup()

  const [mutate] = useAddPsychologistProfileRatingMutation()

  const isWizard = useCheckIsWizard()
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  const { data, loading } = useGetPsychologistActualData({
    variables: {
      id: psychologist.id
    }
  })
  const psychologistActual = data?.psychologistsCatalogItem
  const [_, setSessionPsychologist] = useSessionStorage(
    PSYCHOLOGIST_WORKS_WITH_TEENS,
    false
  )
  useEffect(() => {
    setSessionPsychologist(psychologistActual?.isWorkingWithTeenagerPatients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psychologistActual?.isWorkingWithTeenagerPatients])

  const isProfileRated = isBrowser
    ? localStorage.getItem('profileRated') === 'true'
    : false

  useEffect(() => {
    if (!isProfileRated) {
      setTimeout(() => {
        showReviewModal()
      }, 10000)
    }
  }, [isProfileRated, showReviewModal])

  const [catalogFilters] = useLocalStorage('catalog', {})
  const [wizardFilters] = useLocalStorage('wizard', {})
  const filters = isWizard ? wizardFilters : catalogFilters

  const variables = useMemo(() => {
    const values = filters ?? {}
    const options = {
      fullyMatch: true,
      sortByRating: true
    }
    const [minAge, maxAge] = values.psychologistAge ?? []
    const excludeFilters = { excludeIds: [psychologist._id] }
    const filter = {
      ...excludeFilters,
      workAreas: values.workAreas,
      isWorkingWithTeenagerPatients: values.isWorkingWithTeenagerPatients,
      workApproaches: values.approaches,
      gender: genderMap[values.gender],
      type: therapyTypeCatalogFilterMap[values.client],
      language: languageMap[values.language],
      formats: [SessionFormat.Video],
      minAge,
      maxAge,
      isLGBTQFriendly: values.friendliness,
      isUrgent: values.isUrgent,
      nearestSlot: isWizard ? true : false,
      psychologistVisibility: isWizard
        ? VisibilityType.Open
        : VisibilityType.Close,
      prices: (values.prices ?? []).map((price) => pricesMap[price]),
      isAdditionalPsychologists: true
    }

    return { options, filter }
  }, [filters, isWizard, psychologist._id])

  const { data: additionalPsychologists, loading: isLoading } =
    useGetFilteredPsychologists({
      variables
    })

  const mainBlockRef = useRef(null)
  const otherPsychologistsCards = useAbTest(
    AbTestsNames.OTHER_PSYCHOLOGISTS_CARDS
  )

  const hideProfileReviewModal = useCallback(() => {
    const isProfileRated = isBrowser
      ? localStorage.getItem('profileRated') === 'true'
      : false
    if (!isProfileRated) {
      mutate({
        variables: {
          input: {
            psychologistId: psychologist.id,
            rating: 0
          }
        }
      })
      if (isBrowser) {
        localStorage.setItem('profileRated', true)
      }
    }

    hideReviewModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainBlock>
      <GuideBlock />
      {visibleReviewModal && (
        <AddPsychologistProfileRatingMainFormModal
          hide={hideProfileReviewModal}
          psychologistId={psychologist._id}
          visible={visibleReviewModal}
        />
      )}
      <div ref={mainBlockRef} className={className}>
        <PsychologistInfo psychologist={psychologist} />
        {!isLoading &&
          isMobile &&
          additionalPsychologists &&
          otherPsychologistsCards === InvariantValues.YES &&
          additionalPsychologists.psychologistsCatalog.items.length >= 3 && (
            <AdditionalPsychologistsBlock
              psychologists={additionalPsychologists.psychologistsCatalog.items}
              scrollRef={mainBlockRef}
            />
          )}
        {loading ? (
          <LoaderBlock />
        ) : (
          <CalendarOrApplicationForm
            isWizard={isWizard}
            psychologist={psychologist}
            visibility={psychologistActual?.visibility}
          />
        )}
      </div>
      {!isLoading &&
        !isMobile &&
        additionalPsychologists &&
        otherPsychologistsCards === InvariantValues.YES &&
        additionalPsychologists.psychologistsCatalog.items.length >= 3 && (
          <AdditionalPsychologistsBlock
            psychologists={additionalPsychologists.psychologistsCatalog.items}
            scrollRef={mainBlockRef}
          />
        )}
    </MainBlock>
  )
})`
  display: flex;
  gap: 24px;
  justify-content: center;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`

export const PsychologistCard = styled(
  ({ psychologist, locationPsychologistId, className }) => {
    useScrollEffect(locationPsychologistId)

    return (
      <div className={className}>
        <SEO
          description={getSEODescription(psychologist)}
          title={getSEOTitle(psychologist)}
          {...getSEOImage(psychologist?.photoUrl)}
        />
        <PsychologistInfoWithCalendar psychologist={psychologist} />
        <Footer />
      </div>
    )
  }
)`
  background: ${color.profile.background};
  padding-top: 48px;
`
